//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnIeB5RqIWuYTXtZiiFh626wKq3VMiFjU",
  authDomain: "localcointrade-62194.firebaseapp.com",
  projectId: "localcointrade-62194",
  storageBucket: "localcointrade-62194.appspot.com",
  messagingSenderId: "407437111162",
  appId: "1:407437111162:web:69eb040b0981a9fc9930c3",
  measurementId: "G-WC3ZVMSXE1",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
